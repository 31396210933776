<template>
  <div class="Results">
    <heading class="Results__Heading">
      <img
        :src="require(`@images/result/${locale}/txt_result.png`)"
        class="Heading__TextImage"
        :alt="$t('result.001')"
      >
    </heading>
    <p
      class="Results__Text"
      v-html="replaceText($t('result.002'), {
        no1_man_name: $t(`character.${rankingTop4[0][0].heroId}`),
        no2_man_name: $t(`character.${rankingTop4[2][0].heroId}`),
        no1_woman_name: $t(`character.${rankingTop4[1][0].heroId}`),
        no2_woman_name: $t(`character.${rankingTop4[3][0].heroId}`)}
      )"
    />
    <div class="Results__HeroesGold HeroesGold">
      <h3 class="HeroesGold__Heading" v-html="$t('result.003')" />
      <div class="HeroesGold__SubHeadings">
        <h4 class="HeroesGold__SubHeading" v-html="$t('result.004')" />
        <h4 class="HeroesGold__SubHeading" v-html="$t('result.005')" />
      </div>
      <div class="HeroesGold__List">
        <hero-item
          v-for="(rankItems,i) in rankingTop4"
          :key="i"
          :rankItems="rankItems"
          colorType="gold"
        />
      </div>
    </div>

    <hr class="Results__Line" />

    <div class="Results__HeroesSilver HeroesSilver">
      <div class="HeroesSilver__List">
        <hero-item v-for="(rankItems, i) in rankingLest" :key="i" :rankItems="rankItems" />
      </div>
    </div>

    <div class="Results__Nav Nav">
      <p class="Nav__Title" v-text="$t('result.006')" />
      <div class="Nav__List">
        <ul class="Nav__ListInner">
          <li class="Nav__ListItem">
            <router-link :to="{name: 'ResultList', params: { lang: locale, page: 1 }}" v-text="$t('result.007')" />
          </li>
          <li class="Nav__ListItem">
            <router-link :to="{name: 'ResultList', params: { lang: locale, page: 2 }}" v-text="$t('result.008')" />
          </li>
          <li class="Nav__ListItem">
            <router-link :to="{name: 'ResultList', params: { lang: locale, page: 3 }}" v-text="$t('result.009')" />
          </li>
          <!-- <li class="Nav__ListItem">
            <router-link :to="{name: 'ResultList', params: { lang: locale, page: 4 }}" v-text="$t('result.010')" />
          </li> -->
        </ul>
      </div>
    </div>

    <hr class="Results__Line" />

    <button-official class="Results__ButtonOfficial" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocaleMixin from '@/mixins/locale'
import LoaderMixin from '@/mixins/loader'
import HeroItem from '@/components/result/hero-item.vue'
import Heading from '@//components/global/heading.vue'
import ButtonOfficial from '@/components/global/button-official.vue'

export default {
  name: 'Results',
  mixins: [LocaleMixin, LoaderMixin],
  components: {
    HeroItem,
    Heading,
    ButtonOfficial
  },
  computed: {
    ...mapGetters('result', ['getResultMale', 'getResultFemale']),
    rankingTop4 () {
      const ranks = ['m-1', 'f-1', 'm-2', 'f-2']
      const list = []
      for (const i of ranks) {
        const _i = i.split('-')
        const heros = _i[0] === 'm' ? this.getResultMale.filter(r => +r.rank === +_i[1]) : this.getResultFemale.filter(r => +r.rank === +_i[1])
        list.push(heros)
      }
      return list
    },
    rankingLest () {
      const ranks = ['m-3', 'f-3', 'm-4', 'f-4', 'm-5', 'f-5', 'm-6', 'f-6', 'm-7', 'f-7', 'm-8', 'f-8', 'm-9', 'f-9', 'm-10', 'f-10']
      const list = []
      for (const i of ranks) {
        const _i = i.split('-')
        const heros = _i[0] === 'm' ? this.getResultMale.filter(r => +r.rank === +_i[1]) : this.getResultFemale.filter(r => +r.rank === +_i[1])
        list.push(heros)
      }
      return list
    }
  },
  methods: {
    replaceText (text, values) {
      let _text = text
      for (const i in values) {
        const reg = new RegExp(`{${i}}`, 'g')
        if (reg.test(_text)) {
          _text = _text.replace(reg, values[i])
        }
      }
      return _text
    }
  }
}
</script>

<style lang="scss" scoped>
.Results {
  @at-root {
    & {
    }
    .Results__Text {
      font-size: $fs-3xlarge;
      text-shadow: $ts-base;
      text-align: center;
      margin-top: 20px;
    }
    .Results__Line {
      height: 2px;
      margin: 0;
      border: 0;
      background: url(#{$img-path}common/line.png) no-repeat center top;
      background-size: 100% 2px;
    }
    .Results__HeroesGold {
      margin-top: 50px;
      margin-bottom: 60px;
    }
    .Results__HeroesSilver {
      margin-top: 20px;
    }
    .Results__Nav {
      margin-top: 35px;
      margin-bottom: 35px;
    }
    .Results__ButtonOfficial {
      margin: 40px auto;
    }
  }
}

.HeroesGold {
  @at-root {
    & {
      width: 100%;
    }
    .HeroesGold__Heading {
      text-align: center;
      text-shadow: $ts-base;
      font-size: 3.4rem;
      margin-bottom: 30px;
      background: url(#{$img-path}result/bg_heading.png) repeat-x center center;
      line-height: 74px;
    }
    .HeroesGold__SubHeadings {
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .HeroesGold__SubHeading {
      width: 290px;
      font-size: $fs-xlarge;
    }
    .HeroesGold__List {
      display: flex;
      flex-wrap: wrap;
      position: relative;
    }
  }
}

.HeroesSilver {
  @at-root {
    & {
      width: 100%;
    }
    .HeroesSilver__List {
      display: flex;
      flex-wrap: wrap;
      position: relative;
    }
  }
}

.Nav {
  @at-root {
    & {
      text-align: center;
    }
    & a {
      text-decoration: underline;
      @include tap-event();
    }
    .Nav__Title {
      font-size: $fs-xlarge;
    }
    .Nav__List {
      margin: 10px 15px 0;
      line-height: 55px;
      border-style: solid;
      border-width: 48px;
      border-image: url(#{$img-path}result/bg_result-ranking.png) 43.64% 9.64% fill stretch;
    }
    .Nav__ListInner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 1 0 0;
      margin: -20px 0;
    }
    .Nav__ListItem {
      width: 50%;
      font-size: $fs-3large;
    }
  }
}
</style>
