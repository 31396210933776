<template>
  <h2 class="Heading">
    <slot />
  </h2>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.Heading {
  @at-root {
    & {
      background: url(#{$img-path}common/bg_page-title.png) no-repeat;
      width: 608px;
      height: 60px;
      margin: 15px auto 0;
      padding-bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
