<template>
  <div class="HeroItem" :class="{'-golden': colorType === 'gold'}">
    <div class="HeroItem__ImageWrapper">
      <img
        :src="heroImage"
        class="HeroItem__Image"
        :height="colorType === 'gold' ? 240 : 183"
        :width="colorType === 'gold' ? 242 : 181"
        alt=""
      >
      <img
        :src="require(`@images/icon/ico_rank${rank}${colorType === 'gold' ? '-m' : '' }.png`)"
        class="HeroItem__Rank"
        height="79"
        width="78"
        alt=""
      >
    </div>
    <div class="HeroItem__Description">
      <p class="HeroItem__Name" v-html="heroNames" />
      <p class="HeroItem__SeriesName" v-html="seriesNames" />
      <p class="HeroItem__VoteCount" v-text="count" />
    </div>
  </div>
</template>

<script>
import LocaleMixin from '@/mixins/locale'
import { delimit } from '@/libraries/util'

export default {
  name: 'HeroItem',
  mixins: [LocaleMixin],
  props: {
    rankItems: {
      type: Array,
      require: false
    },
    colorType: {
      type: String,
      require: false,
      default: 'silver'
    }
  },
  computed: {
    sortedItems () {
      return this.rankItems.map(r => r).sort((a, b) => a.count < b.count ? 1 : -1)
    },
    heroImage () {
      return require(`@images/hero-faces/${this.sortedItems[0].heroId}.png`)
    },
    rank () {
      return this.rankItems[0].rank
    },
    count () {
      const count = delimit(this.rankItems[0].totalCount, this.locale)
      const text = this.$t('result.011')
      return text.replace(/{votes}/g, count)
    },
    heroNames () {
      if (this.rankItems.length < 1) return ''
      const names = this.sortedItems.map(r => this.$t(`character.${r.heroId}`))
      const uniqueNames = [...new Set(names)]
      return uniqueNames.join('<br>')
    },
    seriesNames () {
      if (this.rankItems.length < 1) return ''
      const names = this.sortedItems.map(r => this.$t(`series.game${r.seriesId}-title`))
      const uniqueNames = [...new Set(names)]
      return uniqueNames.join('<br>')
    }
  }
}
</script>

<style lang="scss" scoped>
.HeroItem {
  @at-root {
    & {
      width: 50%;
      padding-top: 40px;
      background: url(#{$img-path}result/bg_result-silver.png) no-repeat center top;
      background-size: contain;

      &.-golden {
        background-image: url(#{$img-path}result/bg_result-gold.png);
        background-position: center 30px;
      }
    }
    .HeroItem__ImageWrapper {
      position: relative;
      text-align: center;
    }
    .HeroItem__Image {
      position: relative;
      z-index: 1;
      margin-top: 30px;
    }
    .HeroItem__Rank {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
    .HeroItem__Description {
      position: relative;
      z-index: 1;
      width: 100%;
      padding: 10px 0;
      background: linear-gradient(
        to right,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0) 1%,
        rgba(0,0,0,1) 15%,
        rgba(0,0,0,1) 50%,
        rgba(0,0,0,1) 85%,
        rgba(0,0,0,0) 100%
      );
      text-align: center;
    }
    .HeroItem__Name {
      font-size: $fs-2xlarge;
    }
    .HeroItem__SeriesName {
      color: #878787;
      font-size: $fs-2middle;
      &.-small {
        font-size: $fs-middle;
      }
    }
    .HeroItem__VoteCount {
      color: #878787;
      font-size: $fs-3large;
    }
  }
}
</style>
