<template>
  <tr class="RankingItem">
    <td class="RankingItem__Td -rank" v-text="rank" />
    <td class="RankingItem__Td -name">
      <span class="RankingItem__Text -name" v-html="heroNames" /><br>
      <span class="RankingItem__Text -series" v-html="seriesNames" />
    </td>
    <td class="RankingItem__Td -votes" v-text="count" />
  </tr>
</template>

<script>
import LocaleMixin from '@/mixins/locale'
import { delimit } from '@/libraries/util'

export default {
  name: 'RankingItem',
  mixins: [LocaleMixin],
  props: {
    rankItems: {
      type: Array,
      require: false
    }
  },
  computed: {
    sortedItems () {
      if (this.rankItems.length < 1) return []
      return this.rankItems.map(r => r).sort((a, b) => a.count < b.count ? 1 : -1)
    },
    rank () {
      if (this.rankItems.length < 1) return ''
      return this.rankItems[0].rank
    },
    heroNames () {
      if (this.rankItems.length < 1) return ''
      const names = this.sortedItems.map(r => this.$t(`character.${r.heroId}`))
      const uniqueNames = [...new Set(names)]
      return uniqueNames.join('<br>')
    },
    count () {
      if (this.rankItems.length < 1) return ''
      const count = delimit(this.rankItems[0].totalCount, this.locale)
      const text = this.$t('result.011')
      return text.replace(/{votes}/g, count)
    },
    seriesNames () {
      if (this.rankItems.length < 1) return ''
      const names = this.sortedItems.map(r => this.$t(`series.game${r.seriesId}-title`))
      const uniqueNames = [...new Set(names)]
      return uniqueNames.join('<br>')
    }
  }
}
</script>

<style lang="scss" scoped>
.RankingItem {
  @at-root {
    & {
      /*
        NOTE: 用途不明のため削除。
        width: 50%;
        margin-top: -40px;
      */
    }
    &::after {
      position: absolute;
      left: 0px;
      display: block;
      width: 100%;
      height: 2px;
      background: url(#{$img-path}common/line_list.png) no-repeat;
      content: '';
    }
    .RankingItem__Td {
      padding-top: 20px;
      padding-bottom: 20px;
      vertical-align: middle;
      line-height: 1.2;
      &.-rank {
        width: 100px;
        padding-left: 50px;
        padding-right: 20px;
        font-size: $fs-xlarge;
        text-align: left;
      }
      &.-name {
        width: auto;
        padding-left: 0;
        font-size: $fs-xlarge;
        text-align: left;
      }
      &.-votes {
        width: 140px;
        padding-right: 50px;
        padding-left: 20px;
        font-size: $fs-xlarge;
        white-space: nowrap;
        text-align: right;
      }
    }
    .RankingItem__Text {
      &.-name {
        font-size: 2.8rem;
      }
      &.-series {
        color: #878787;
        font-size: 2rem;
      }
    }
  }
}
</style>
