<template>
  <div class="ResultsList">
    <heading class="Results__Heading">
      <img
        :src="require(`@images/result/${locale}/txt_result.png`)"
        class="Heading__TextImage"
        :alt="$t('result.001')"
      >
    </heading>

    <result-list-nav class="ResultsList__Nav" />

    <table class="ResultsList__Ranking Ranking">
      <thead class="Ranking__Heading">
        <tr class="Ranking__Tr">
          <th class="Ranking__Th -rank" v-text="$t('result.012')" />
          <th class="Ranking__Th -name">
            <p v-text="$t('result.014')" />
            <p class="Ranking__SeriesName" v-text="$t('result.015')" />
          </th>
          <th class="Ranking__Th -votes" v-text="$t('result.013')" />
        </tr>
      </thead>
      <tbody class="Ranking__Body">
        <list-item v-for="(rankItems,i) in rankList" :key="i" :rankItems="rankItems" />
      </tbody>
    </table>

    <result-list-nav class="ResultsList__Nav" />

    <hr class="ResultsList__Line" />
    <button-official class="ResultsList__ButtonOfficial" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocaleMixin from '@/mixins/locale'
import LoaderMixin from '@/mixins/loader'
import ListItem from '@/components/result/list-item.vue'
import ResultListNav from '@/components/result/result-list-nav.vue'
import Heading from '@//components/global/heading.vue'
import ButtonOfficial from '@/components/global/button-official.vue'

export default {
  name: 'ResultsList',
  mixins: [LocaleMixin, LoaderMixin],
  components: {
    ListItem,
    ResultListNav,
    Heading,
    ButtonOfficial
  },
  computed: {
    ...mapGetters('result', ['getResultAll']),
    page1Items () {
      const min = 0
      const max = 200
      const res = []
      for (let i = min; i < max; i++) {
        const heroes = this.getResultAll.filter(r => +r.rank === (i + 1))
        if (heroes.length > 0) {
          const sameHeroIds = [...new Set(heroes.map(h => h.sameHeroId))].filter(Boolean)
          if (sameHeroIds.length > 0) {
            sameHeroIds.forEach(s => res.push(heroes.filter(h => h.sameHeroId === s)))
          } else {
            heroes.forEach(h => res.push([h]))
          }
        }
      }
      return res
    },
    page2Items () {
      const min = 200
      const max = 400
      const res = []
      for (var i = min; i < max; i++) {
        const heroes = this.getResultAll.filter(r => +r.rank === (i + 1))
        if (heroes.length > 0) {
          const sameHeroIds = [...new Set(heroes.map(h => h.sameHeroId))].filter(Boolean)
          if (sameHeroIds.length > 0) {
            sameHeroIds.forEach(s => res.push(heroes.filter(h => h.sameHeroId === s)))
          } else {
            heroes.forEach(h => res.push([h]))
          }
        }
      }
      return res
    },
    page3Items () {
      const min = 400
      const max = 600
      const res = []
      for (var i = min; i < max; i++) {
        const heroes = this.getResultAll.filter(r => +r.rank === (i + 1))
        if (heroes.length > 0) {
          const sameHeroIds = [...new Set(heroes.map(h => h.sameHeroId))].filter(Boolean)
          if (sameHeroIds.length > 0) {
            sameHeroIds.forEach(s => res.push(heroes.filter(h => h.sameHeroId === s)))
          } else {
            heroes.forEach(h => res.push([h]))
          }
        }
      }
      return res
    },
    page4Items () {
      const min = 601
      const max = this.getResultAll.length
      const res = []
      for (var i = min; i < max; i++) {
        const heroes = this.getResultAll.filter(r => +r.rank === (i + 1))
        if (heroes.length > 0) {
          const sameHeroIds = [...new Set(heroes.map(h => h.sameHeroId))].filter(Boolean)
          if (sameHeroIds.length > 0) {
            sameHeroIds.forEach(s => res.push(heroes.filter(h => h.sameHeroId === s)))
          } else {
            heroes.forEach(h => res.push([h]))
          }
        }
      }
      return res
    }
  },
  watch: {
    '$route.params.page' (value) {
      this.updateList()
    }
  },
  data () {
    return {
      rankList: []
    }
  },
  created () {
    this.updateList()
  },
  methods: {
    updateList () {
      const pageNum = +this.$route.params.page
      switch (pageNum) {
        case 1:
          this.rankList = this.page1Items
          break
        case 2:
          this.rankList = this.page2Items
          break
        case 3:
          this.rankList = this.page3Items
          break
        case 4:
          this.rankList = this.page4Items
          break
        default:
          this.rankList = []
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ResultsList {
  @at-root {
    & {}
    .ResultsList__Ranking {
      margin-bottom: 30px;
    }
    .ResultsList__ButtonOfficial {
      margin: 40px auto 0;
    }
    .ResultsList__Nav {
      margin: 30px 15px;
    }
    .ResultList__Line {
      height: 2px;
      margin: 0;
      background: url(#{$img-path}common/line.png) no-repeat center center;
      background-size: 100% 2px;
      border: 0;
    }
  }
}

.Ranking {
  @at-root {
    & {
      position: relative;
      width: 100%;
      background-color: rgba(0, 0, 0, .2);
      background: url(#{$img-path}common/line_list.png) no-repeat center bottom;
      border-spacing: 0;
    }
    .Ranking__Tr::after {
      content: '';
      position: absolute;
      left: 0px;
      display: block;
      width: 100%;
      height: 2px;
      background: url(#{$img-path}common/line_list.png) no-repeat;
    }
    .Ranking__Th {
      height: 70px;
      vertical-align: middle;
      font-weight: normal;
      font-size: $fs-large;
      line-height: 1.2;
      &.-rank {
        width: 100px;
        padding-left: 50px;
        padding-right: 20px;
        text-align: left;
      }
      &.-name {
        width: 415px;
        padding-left: 0;
        text-align: left;
      }
      &.-votes {
        width: 125px;
        padding-left: 20px;
        padding-right: 50px;
        text-align: right;
      }
    }
    .Ranking__SeriesName {
      color: #878787;
    }
  }
}
</style>
