<template>
  <div class="ResultsListNav">
    <button-back class="ResultsListNav__Back" @click="$router.push({name: 'Result', params: {lang: locale}})"/>
    <div class="ResultsListNav__List">
      <ul class="ResultsListNav__ListInner">
        <li class="ResultsListNav__ListItem" :class="{'-active': +$route.params.page === 1}">
          <router-link :to="{ name: 'ResultList', params: { lang: locale, page: 1 }}" v-text="$t('result.007')" />
        </li>
        <li class="ResultsListNav__ListItem" :class="{'-active': +$route.params.page === 2}">
          <router-link :to="{ name: 'ResultList', params: { lang: locale, page: 2 }}" v-text="$t('result.008')" />
        </li>
        <li class="ResultsListNav__ListItem" :class="{'-active': +$route.params.page === 3}">
          <router-link :to="{ name: 'ResultList', params: { lang: locale, page: 3 }}" v-text="$t('result.009')" />
        </li>
        <!-- <li class="ResultsListNav__ListItem" :class="{'-active': +$route.params.page === 4}">
          <router-link :to="{ name: 'ResultList', params: { lang: locale, page: 4 }}" v-text="$t('result.010')" />
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import LocaleMixin from '@/mixins/locale'
import ButtonBack from '@/components/global/button-back.vue'

export default {
  name: 'ResultsListNav',
  mixins: [LocaleMixin],
  components: {
    ButtonBack
  }
}
</script>

<style lang="scss" scoped>
.ResultsListNav {
  @at-root {
    & {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        text-decoration: underline;
        @include tap-event();
      }
    }
    .ResultsListNav__List {
      display: flex;
      flex: 1 0 0;
      border-style: solid;
      border-width: 48px;
      border-image: url(#{$img-path}result/bg_result-ranking.png) 48 fill stretch;
      font-size: $fs-3large;
      margin-left: 4px;
      padding: 10px 0;
    }
    .ResultsListNav__ListInner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 1 0 0;
      margin: -35px 0;
    }
    .ResultsListNav__ListItem {
      text-align: center;
      width: 50%;
      display: inline-block;
      white-space: nowrap;
      &.-active a {
        text-decoration: none;
        cursor: default;
      }
      &:nth-child(-n+2) {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
